import React, { Fragment } from 'react'

/* eslint  no-console: 0 */
class VitrineHeader extends React.Component {
  render() {
    return (
        <Fragment>
          <header id="site-header" className="header-footer-group" role="banner">
            <div className="header-inner section-inner">
              <div className="container-fluid">
                <div className="row" id="topnav-main-row">
                  <div id="mainlogo"
                       className="mainlogo ct-header__mainlogo site-logo col-8 col-lg-4 order-1 order-lg-1">
                    <a className="mainlogo-link logo-link"
                       href="https://www.diw.de/">

                      <img id="logo" src={"./logos/diw_logo.svg"}           /></a></div>
                  <div className="col-hamburger col-4 col-lg-0 order-2 order-lg-3"><span
                      className="toggle nav-toggle mobile-nav-toggle"
                      data-toggle-target=".menu-modal" data-toggle-body-class="showing-menu-modal"
                      aria-expanded="false" data-set-focus=".close-nav-toggle"></span></div>
                  <div id="mainnav"
                       className="ct-header__mainnav header-navigation-wrapper col-0 col-sm-0 col-md-0 col-lg-8 order-3 order-lg-2">
                    <nav className="primary-menu-wrapper expanded-menu" role="navigation"
                         aria-label="Primary">
                      <ul className="primary-menu menu reset-list-style">
                        <li id="menu-item-32"
                            className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-25 current_page_item menu-item-33 ct-header__link mainlink">
                          <a href="#" aria-current="page">Daten suchen</a></li>
                        <li id="menu-item-33"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-33 ct-header__link mainlink">
                          <a href="https://www.diw.de/de/diw_01.c.623262.de/seiten/datenangebote.html">Daten</a></li>
                        <li id="menu-item-564"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-564 ct-header__link mainlink">
                          <a href="https://www.diw.de/de/diw_01.c.626639.de/publikationssuche.html">Publikationen</a></li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </Fragment>
    )
  }
}

export default VitrineHeader
